import { CollToAction } from "@/components/site/parts/CollToAction";
import { CollToActionContent } from "@/components/site/parts/CollToAction/types";
import { CounterSection } from "@/components/site/parts/CounterSection";
import { CounterItems } from "@/components/site/parts/CounterSection/types";
import { FlipCard } from "@/components/site/parts/FlipCard";
import { GuideWorkingCard } from "@/components/site/parts/GuideWorkingCard";
import { ScrollableSections } from "@/components/site/parts/ScrollableSections";
import { useSiteContext } from "@/utils/siteContext";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React, { useRef } from "react";

import { Button } from "../../parts/Button";
import {
  MainSectionProps,
  HearFromOurClientsProps,
  GuideWorkingWithUsProps,
  WeServeSectionProps,
  MemberSpotlightSectionProps,
  ComprehensiveCareSectionProps,
} from "./types";

const HomePageComponent = () => {
  const { t } = useTranslation("common");
  const { setOpenModal, setClosingModal } = useSiteContext();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const handleOpenModal = () => {
    setOpenModal(true);
    setClosingModal(false);
  };
  const guideWorkinClasses = [
    {
      icon: "bg-site-background-gray",
      wrapper: "border-primary-1",
    },
    {
      icon: "bg-gray-4",
      wrapper: "border-site-bacground-blue-1",
    },
    {
      icon: "bg-gray-9",
      wrapper: "border-green",
    },
    {
      icon: "bg-orange-10",
      wrapper: "border-orange-1",
    },
  ];
  const comprehensiveCareCardsBtnColors = ["darkBlue", "darkOrange", "green", "darkPurple"];

  const sections = [
    { key: "mainSection", type: "" },
    { key: "counterSection", type: "" },
    { key: "hearFromOurClients", type: "" },
    { key: "guideWorkingWithUs", type: "" },
    { key: "weServeSection", type: "" },
    { key: "collToActionSection", type: "" },
    { key: "memberSpotlightSection", type: "" },
    { key: "comprehensiveCareSection", type: "" },
  ];
  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`home.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const {
    mainSection,
    counterSection,
    hearFromOurClients,
    guideWorkingWithUs,
    weServeSection,
    collToActionSection,
    memberSpotlightSection,
    comprehensiveCareSection,
  } = sectionContents as {
    mainSection: MainSectionProps;
    collToActionSection: CollToActionContent;
    counterSection: CounterItems[];
    hearFromOurClients: HearFromOurClientsProps;
    guideWorkingWithUs: GuideWorkingWithUsProps;
    weServeSection: WeServeSectionProps;
    memberSpotlightSection: MemberSpotlightSectionProps;
    comprehensiveCareSection: ComprehensiveCareSectionProps;
  };
  return (
    <>
      <div className="main relative bg-gray-6 pt-[8rem] md:h-[894px] md:pt-[165px] lg:pt-[200px]">
        <div className="mx-auto h-full max-w-[2000px]">
          <div className="container relative h-full lg:pr-[55%] xl:pr-[5%]">
            <div className="max-w-[877px]">
              <h1 className="mb-6 text-site-text-48-57 font-black md:mb-9 lg:text-site-text-72-80 xl:text-site-text-110-110">
                {mainSection.title}
              </h1>
              <p className="mb-6 max-w-[510px] text-site-text-20-22 font-light md:mb-[37px] md:text-site-text-28-40">
                {mainSection.description}
              </p>
              <Button
                size="medium"
                color="darkOrange"
                textSize="medium"
                font="light"
                rounded="medium"
                classButton="hidden w-fit sm:flex"
                href={mainSection.buttonLink || "#"}>
                <span>{mainSection.buttonText}</span>
                <span className="sr-only">about SimpleTherapy company and its services</span>
              </Button>
            </div>
          </div>
          <div className="relative bottom-0 right-0 flex h-full justify-end md:absolute md:max-h-[500px] lg:max-h-[600px] xl:max-h-[700px] 2xl:max-h-[810px]">
            <svg
              className="absolute right-[45px] top-0 max-w-[130px] xs:right-0 xs:top-[100px] sm:max-w-[150px] md:top-[120px] md:max-w-[180px] lg:top-[150px] lg:max-w-[210px] xl:top-[210px] xl:max-w-full"
              width="251"
              height="147"
              viewBox="0 0 251 147"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5 1.5C25.7373 1.50005 52.4268 17.7757 64.0142 40C149 203 215.5 81 285.5 145"
                stroke="url(#paint0_linear_1554_5365)"
                strokeWidth="3"
                strokeLinecap="round"
                className="svg-elem-2"></path>
              <defs>
                <linearGradient
                  id="paint0_linear_1554_5365"
                  x1="13.672"
                  y1="14.6735"
                  x2="284.809"
                  y2="102.481"
                  gradientUnits="userSpaceOnUse">
                  <stop stopColor="#FF790C" stopOpacity="0"></stop>
                  <stop offset="0.51" stopColor="#FF790C"></stop>
                  <stop offset="1" stopColor="#FF790C" stopOpacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
            <Image
              src={`/images/site/home/${mainSection.imageUrl}`}
              alt="Simple Therapy"
              priority
              height={710}
              width={824}
              className="hidden h-full w-auto object-cover xs:block sm:min-h-[500px]"
            />
            <Image
              src={`/images/site/home/mainImageMob.webp`}
              alt="Simple Therapy"
              priority
              height={239}
              width={360}
              className="block h-[230px] w-auto object-cover xs:hidden sm:min-h-[500px]"
            />
            <svg
              className="absolute -bottom-[18px] -left-[50px] max-h-[210px] xs:-left-[35px] sm:-bottom-[25px] sm:left-0 sm:max-h-[400px] md:-bottom-[25px] md:left-0 lg:-bottom-[50px] lg:left-[25px] xl:-bottom-[20px] xl:left-[65px] xl:max-h-full"
              width="178"
              height="515"
              viewBox="0 0 178 515"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M28.1061 597.2C189.461 546.7 89.4629 425.7 106.461 393.2C123.46 360.7 198.96 300.2 166.96 344.7C134.96 389.2 165.658 303.365 123.46 286.7C-29.9771 226.106 -20.0412 113.2 59.9592 3.7002"
                stroke="url(#paint0_linear_1554_5363)"
                strokeWidth="6"
                strokeLinecap="round"
                className="svg-elem-1"></path>
              <defs>
                <linearGradient
                  id="paint0_linear_1554_5363"
                  x1="68.1538"
                  y1="133.2"
                  x2="68.1538"
                  y2="597.2"
                  gradientUnits="userSpaceOnUse">
                  <stop stopColor="#FF790C" stopOpacity="0"></stop>
                  <stop offset="0.51" stopColor="#FF790C"></stop>
                  <stop offset="1" stopColor="#FF790C" stopOpacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <CounterSection items={counterSection} classWrapper="bg-orange-1 relative" />
      <div className="comprehensiveCareSection bg-gray-8 py-10 md:py-20">
        <div className="container">
          <div className="mb-10 grid grid-cols-1 gap-5 md:mb-20 md:grid-cols-2 md:gap-10">
            <h2 className="text-site-text-28-33 md:text-site-text-48-57">{comprehensiveCareSection.title}</h2>
            <p className="text-site-text-16-24 font-light md:text-site-text-18-27">
              {comprehensiveCareSection.description}
            </p>
          </div>
          <div className="wrapper relative">
            {comprehensiveCareSection.cards.map((card, index) => (
              <div
                key={index}
                className="card sticky top-25 mb-5 grid grid-cols-1 gap-10 rounded-[24px] bg-white p-5 shadow-card-3 md:mb-10 md:grid-cols-2 md:gap-20 md:p-10">
                <div className="flex flex-col items-start justify-center">
                  <Image
                    src={`/images/site/home/${card.logo}`}
                    alt="Simple Therapy "
                    width={576}
                    height={44}
                    className="mb-5 h-full max-h-11 w-auto object-cover sm:min-h-11 md:mb-10"
                  />
                  <p className="mb-6 text-site-text-18-27 md:text-site-text-24-33">{card.description}</p>
                  {card.buttonText && (
                    <Button
                      size="large"
                      color={
                        comprehensiveCareCardsBtnColors[index] as
                          | "darkBlue"
                          | "orange"
                          | "green"
                          | "darkPurple"
                          | "darkOrange"
                          | undefined
                      }
                      textSize="large"
                      font="normal"
                      rounded="medium"
                      href={card.buttonLink || "#"}
                      classButton="w-full md:w-fit gap-2">
                      <span>{card.buttonText}</span>
                      <span className="sr-only">{`Learn More about ${(card?.buttonLink || "/").replace("/", "")}`}</span>
                      <span className="relative right-0 text-[20px] transition-all duration-300 group-hover:-right-1 group-hover:text-[24px]">
                        <i className="icon-arrowR flex self-center text-[18px] font-[200]"></i>
                      </span>
                    </Button>
                  )}
                </div>
                <div className="max-h-[295px] sm:min-h-[350px] md:min-h-[450px] lg:max-h-[536px] lg:min-h-[536px]">
                  <Image
                    src={`/images/site/home/${card.image}`}
                    alt="Simple Therapy"
                    width={576}
                    height={536}
                    className="mx-auto h-full w-auto rounded-[8px] object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="memberSpotlightSection bg-white py-10 md:py-30">
        <div className="container">
          <div className="grid grid-cols-1 gap-10 md:grid-cols-[2fr_1fr] md:gap-14 lg:gap-20">
            <div>
              <div className="mb-4 flex flex-row justify-between gap-6 sm:justify-center sm:gap-16 md:mb-0 md:justify-start md:gap-0">
                <div className="flex flex-col">
                  <h2 className="mb-4 text-site-text-32-35 md:mb-10 md:text-site-text-48-57">
                    {memberSpotlightSection.title}
                  </h2>
                  <p className="flex flex-col text-site-text-16-24 md:hidden md:text-site-text-18-27">
                    <span className="mx-1 font-medium">{memberSpotlightSection.author}</span>
                    <span className="font-light">{memberSpotlightSection.position}</span>
                  </p>
                </div>
                <div className="flex shrink-0 justify-center md:hidden">
                  <div className="relative h-full max-h-[328px] w-full max-w-[328px] sm:max-h-[436px] sm:max-w-[360px]">
                    <span className="absolute -left-[30px] top-5 text-[116px] font-semibold text-orange-1 md:-left-[50px] md:top-20">
                      “
                    </span>
                    <div className="h-auto overflow-hidden rounded-[16px]">
                      <Image
                        src={`/images/site/home/${memberSpotlightSection.imageUrl}`}
                        alt={memberSpotlightSection.author || "Simple Therapy"}
                        width={131}
                        height={131}
                        className="h-full max-h-[131px] w-[131px] shrink-0 object-cover object-top"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p className="mb-4 text-site-text-18-21 font-light md:mb-6 md:text-site-text-28-40">
                {memberSpotlightSection.description}
              </p>
              <p className="hidden text-site-text-16-24 md:block md:text-site-text-18-27">
                <span className="font-medium">{memberSpotlightSection.author}</span>
                <span className="mx-1">|</span>
                <span className="font-light">{memberSpotlightSection.position}</span>
              </p>
            </div>
            <div className="hidden shrink-0 justify-center md:flex">
              <div className="relative h-full max-h-[328px] w-full max-w-[328px] sm:max-h-[436px] sm:max-w-[360px]">
                <span className="absolute -left-[15px] top-5 text-[150px] font-semibold text-orange-1 md:-left-[50px] md:top-20">
                  “
                </span>
                <div className="flex h-full items-center justify-center overflow-hidden rounded-[16px]">
                  <Image
                    src={`/images/site/home/${memberSpotlightSection.imageUrl}`}
                    alt={memberSpotlightSection.author || "Simple Therapy"}
                    width={360}
                    height={436}
                    className="object-cover object-top md:max-h-[272px] md:max-w-[272px] md:rounded-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={containerRef} className="relative h-full bg-site-background-gray py-15 md:py-30">
        <div className="container sticky top-24">
          <ScrollableSections
            itemSection={weServeSection.items}
            container={containerRef}
            title={weServeSection.title}
          />
        </div>
      </div>
      <div className="bg-white py-10 md:py-30">
        <div className="container">
          <h2 className="mb-2 text-center text-site-text-32-38 md:text-site-text-48-57">
            {hearFromOurClients.title}
          </h2>
          <p className="text-site-text-16-18 mb-8 text-center font-light md:mb-10 md:text-site-text-24-33">
            {hearFromOurClients.description}
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            {hearFromOurClients.cards.map((card, index) => (
              <FlipCard key={index} card={card} />
            ))}
          </div>
        </div>
      </div>
      <div className="bg-gray-8 pb-8 pt-8 md:pb-20 md:pt-16">
        <div className="mx-auto max-w-[1324px] px-3.5">
          <h2 className="mb-4 text-center text-site-text-32-38 font-medium md:text-site-text-58-69">
            {guideWorkingWithUs.title}
          </h2>
          <p className="mx-auto mb-10 max-w-[1200px] text-center text-site-text-16-24 font-normal md:mb-20 md:text-site-text-18-27">
            {guideWorkingWithUs.description}
          </p>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4">
            {guideWorkingWithUs.cards.map((card, index) => (
              <GuideWorkingCard
                key={index}
                card={card}
                classWrapper={`mb-8 md:mb-0 bg-white ${guideWorkinClasses[index].wrapper}`}
                classIcon={` ${guideWorkinClasses[index].icon}`}
              />
            ))}
          </div>
        </div>
      </div>
      <CollToAction
        content={{
          title: collToActionSection.title,
          description: collToActionSection.description,
          buttonText: collToActionSection.buttonText,
          imageBrand: collToActionSection.imageBrand,
        }}
        eventHandler={handleOpenModal}
        classWrapper="bg-white"
        classContentWrapper="max-w-[650px]"
        classTitle="items-center gap-4"
        classDescription="text-center md:text-left"
        classButton="bg-site-btn-orange text-white hover:bg-site-btn-orange-hover w-full md:w-fit"
      />
    </>
  );
};

export default HomePageComponent;

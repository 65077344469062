import classNamees from "classnames";
import Link from "next/link";
import React, { ReactNode } from "react";

interface ButtonProps {
  children: ReactNode;
  href?: string;
  type?: "submit" | "button";
  classButton?: string;
  eventHandler?: () => void;
  size?: "small" | "medium" | "large";
  color?: "orange" | "darkOrange" | "green" | "darkPurple" | "darkBlue";
  font?: "light" | "normal" | "bold";
  textSize?: "small" | "medium" | "large";
  rounded?: "small" | "medium" | "large";
  disabled?: boolean;
  target?: "blank" | "self" | "parent" | "top";
}

export const Button = ({
  children,
  href,
  type,
  classButton,
  eventHandler,
  color,
  size,
  font,
  textSize,
  rounded,
  disabled,
  target,
}: ButtonProps) => {
  const classDefault =
    "group flex items-center justify-center px-6 py-2 text-center text-site-text-16-23  transition-all duration-300 ";

  const colorsBtn = {
    orange: "bg-site-btn-orange hover:bg-site-btn-orange-hover text-white",
    darkOrange: "bg-orange-1 hover:bg-site-btn-orange-hover text-white",
    green: "bg-green hover:bg-green-1-hover text-white",
    darkBlue: "bg-site-background-blue-1 hover:bg-site-background-blue-1-hover text-white",
    darkPurple: "bg-purple hover:bg-purple-hover text-white",
  };

  const sizeText = {
    small: "text-site-text-16-23",
    medium: "text-site-text-16-23 md:text-site-text-18-27",
    large: "text-site-text-18-27 md:text-site-text-24-33",
  };

  const sizeBtn = {
    small: "md:min-w-[170px] md:min-h-[54px] min-h-[40px] min-w-[150px]",
    medium: "md:min-w-[196px] md:min-h-[51px] min-h-[40px] min-w-[160px]",
    large: "md:min-w-[210px] md:min-h-[56px] min-h-[40px] min-w-[170px]",
  };

  const fontBtn = {
    light: "font-light",
    normal: "font-normal",
    bold: "font-bold",
  };

  const roundedBtn = {
    small: "rounded-[24px]",
    medium: "rounded-[48px]",
    large: "rounded-[64px]",
  };

  const targetBtn = {
    blank: "_blank",
    self: "_self",
    parent: "_parent",
    top: "_top",
  };

  const classBtn = classNamees(
    classDefault,
    color && colorsBtn[color],
    size && sizeBtn[size],
    font && fontBtn[font],
    sizeText[textSize || "small"],
    rounded && roundedBtn[rounded],
    classDefault
  );

  return href ? (
    <Link
      href={href.includes("@") ? "mailto:" + href : href}
      className={classNamees(classBtn, classButton)}
      target={target ? targetBtn[target] : "_self"}
      title={`Link ${href ? "to " + href.replace("/", "") : ""}`}
      aria-label={`Learn More ${href ? "about " + href.replace("/", "") : ""}`}>
      {children}
    </Link>
  ) : (
    <button
      {...(type ? { type: type } : {})}
      onClick={eventHandler}
      className={classNamees(classBtn, classButton, {
        "cursor-not-allowed !bg-site-background-gray6 hover:!bg-site-background-gray6": disabled,
      })}
      aria-label={`Button`}
      {...(disabled ? { disabled: disabled } : {})}>
      {children}
    </button>
  );
};

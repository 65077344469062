import { getRoute } from "@/utils/navigationUtils";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

const MenuDropdown = ({ item, open, setOpen, slugs, locale }: any) => {
  const pathname = usePathname();

  const eventHandler = () => {
    return open && setOpen(!open);
  };

  return (
    <>
      <ul className="mb-0 mt-6 flex flex-col gap-6 md2:my-5.5 md2:gap-4">
        {item.map((item: any, index: number) => (
          <li key={index}>
            <Link
              onClick={eventHandler}
              href={getRoute(item.name, slugs, locale)}
              area-label={`Learn More about ${item.label}`}
              className={classNames(
                "group relative flex items-center gap-2.5 rounded-md text-site-text-18-21 font-light md2:px-4 md2:text-site-text-14-21"
              )}>
              <span
                className={classNames(
                  "relative transition-all ease-in-out before:absolute before:bottom-0 before:left-[50%] before:h-[1px] before:w-0 before:origin-center before:bg-site-btn-orange before:transition-[width] before:duration-700 before:ease-in-out after:absolute after:bottom-0 after:right-[50%] after:h-[1px] after:w-0 after:origin-center after:bg-site-btn-orange after:transition-[width] after:duration-700 after:ease-in-out hover:before:w-[50%] hover:after:w-[50%]",
                  pathname && pathname.includes(item.route)
                    ? "font-medium text-site-btn-orange before:w-[50%] after:w-[50%]"
                    : ""
                )}>
                <Trans i18nKey={"menu." + item.label} defaults="" />
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default MenuDropdown;
